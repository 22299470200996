@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

app-root {
	min-height: 100%;
	height: fit-content;
	display: block;
}

@layer base {
	@font-face {
		font-family: Selawik;
		font-weight: 300;
		src: url(/assets/fonts/selawkl.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 400;
		src: url(/assets/fonts/selawksl.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 500;
		src: url(/assets/fonts/selawk.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 600;
		src: url(/assets/fonts/selawksb.ttf);
	}

	@font-face {
		font-family: Selawik;
		font-weight: 700;
		src: url(/assets/fonts/selawkb.ttf);
	}
}

.cdk-overlay-pane {
	div.mat-mdc-menu-panel {
		max-width: none;
	}
}

//Fixes issue with Tailwind and Material 15. With new version in future should be able to remove
.mdc-notched-outline__notch {
	border-right: none;
}

button.mat-mdc-mini-fab,
button.mat-mdc-fab,
button.mat-mdc-mini-fab:hover,
button.mat-mdc-fab:hover {
	box-shadow: none;
}

app-manual-parts-input {
	.mat-mdc-option {
		display: block;
	}
}

app-project-rack-solid {
	.mat-mdc-dialog-content {
		max-height: none;
	}
}
//Removes arrows from number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.no-wrap-mat-form-field {
	width: 100%;
	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	.mat-mdc-text-field-wrapper {
		height: 30px;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		padding: 0;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 30px;
	}
}

app-main-toolbar {
	.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
		right: 6px;
	}
	.mat-badge-small.mat-badge-above .mat-badge-content {
		top: 0;
	}
}
app-main-toolbar::after {
	height: 4px;
	display: block;
	top: 100%;
	right: 0px;
	left: 0px;
	background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
	content: '';
}
app-project {
	height: 100%;
	display: block;
	min-height: 100vh;
}

app-first-login-reset-password,
app-change-password-request,
app-login {
	height: calc(100vh - 50px);
	display: block;
}

.mat-mdc-dialog-container app-login {
	height: 400px;
}

.task-type-0 {
}

.task-type-1 {
	background-color: #eff1f3;
}

.task-type-2 {
	background-color: #f4ede5;
}

.task-type-3 {
	background-color: #e8edfb;
}

.task-type-4 {
	background-color: #bbf7d0;
}

.task-type-0-hours {
}

.task-type-1-hours {
	color: #64748b;
}

.task-type-2-hours {
	color: #964b00;
}

.task-type-3-hours {
	color: #1d4ed8;
}

.task-type-4-hours {
	color: #166534;
}

.mat-mdc-checkbox.mat-accent.task-type-0 {
}

.mat-mdc-checkbox.mat-accent.task-type-1 {
	--mdc-checkbox-selected-focus-icon-color: #64748b;
	--mdc-checkbox-selected-hover-icon-color: #64748b;
	--mdc-checkbox-selected-icon-color: #64748b;
	--mdc-checkbox-selected-pressed-icon-color: #64748b;
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-unselected-focus-icon-color: #cecece;
	--mdc-checkbox-unselected-hover-icon-color: #cecece;
}

.mat-mdc-checkbox.mat-accent.task-type-2 {
	--mdc-checkbox-selected-focus-icon-color: #964b00;
	--mdc-checkbox-selected-hover-icon-color: #964b00;
	--mdc-checkbox-selected-icon-color: #964b00;
	--mdc-checkbox-selected-pressed-icon-color: #964b00;
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-unselected-focus-icon-color: #cecece;
	--mdc-checkbox-unselected-hover-icon-color: #cecece;
}

.mat-mdc-checkbox.mat-accent.task-type-3 {
	--mdc-checkbox-selected-focus-icon-color: #1d4ed8;
	--mdc-checkbox-selected-hover-icon-color: #1d4ed8;
	--mdc-checkbox-selected-icon-color: #1d4ed8;
	--mdc-checkbox-selected-pressed-icon-color: #1d4ed8;
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-unselected-focus-icon-color: #cecece;
	--mdc-checkbox-unselected-hover-icon-color: #cecece;
}

.mat-mdc-checkbox.mat-accent.task-type-4 {
	--mdc-checkbox-selected-focus-icon-color: #166534;
	--mdc-checkbox-selected-hover-icon-color: #166534;
	--mdc-checkbox-selected-icon-color: #166534;
	--mdc-checkbox-selected-pressed-icon-color: #166534;
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-unselected-focus-icon-color: #cecece;
	--mdc-checkbox-unselected-hover-icon-color: #cecece;
}

.transit-time {
	background-color: #ecf0e6;
}

.shipping-buffer {
	background-color: #fef7e7;
}
.mat-mdc-table .mdc-data-table__row {
	@apply h-10 max-h-10;
}

.mat-mdc-table .mat-mdc-header-row {
	@apply h-10 bg-gray-300;
}
.mat-mdc-table .mat-column-def {
	flex: 1;
}
.mdc-data-table__header-cell {
	@apply text-lg border-b-2 border-black;
}

.mat-form-qty-padding .mat-mdc-text-field-wrapper {
	@apply pr-1;
}

.transit-column {
	.mat-sort-header-container {
		@apply justify-center;
	}
}

.mdc-list-item__primary-text {
	@apply flex flex-col justify-center items-start;
}

.audit-option span.mdc-list-item__primary-text {
	width: 100%;
}

.option-no-click.mat-mdc-option.mdc-list-item--disabled {
	opacity: 1;
	cursor: default;
}
